
import { defineComponent } from "vue"
import PublicHeader from "@/components/public/PublicHeader.vue"
import PublicFooter from "@/components/public/PublicFooter.vue"

// @ is an alias to /src

export default defineComponent({
  name: "Home",
  components: {
    PublicHeader,
    PublicFooter,
  },
})
